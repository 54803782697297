<template>
  <messages
    :title="$t('messages for me')"
    title-icon="mdi-star"
    page-size="5"
    days-ago="60"
    ignore-staff
    no-server-pagination
    no-scope
    no-search
    no-filter
    no-reload
    no-dedicated
    dedicated-only
    @foundDedicatedMessages="$emit('foundDedicatedMessages', $event)"
  />
</template>

<script>
import Messages from "@/components/msg//Messages";

export default {
  name: "DedicatedMessages",
  components: {
    Messages,
  },
};
</script>

<i18n>
  {
    "en": {
      "messages for me": "Messages for me"
    },
    "de": {
      "messages for me": "Nachrichten für mich"
    }
  }
</i18n>
