<template>
  <v-card :loading="loading">
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        <v-icon class="mr-4">mdi-stack-overflow</v-icon>
        {{ $t("overview") }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text v-if="!loading">
      <v-simple-table class="ma-4" dense>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>{{ $t("active products") }}:</td>
              <td>{{ activeProducts }}</td>
            </tr>
            <tr v-if="$store.getters.apiRoles.includes('API-INV')">
              <td>{{ $t("open invoices") }}:</td>
              <td>{{ openInvoices }}</td>
            </tr>
            <tr v-if="$store.getters.apiRoles.includes('API-INV')">
              <td>{{ $t("open invoices amount") }}:</td>
              <td>{{ formatMoney(openInvoicesSum, "CHF") }}</td>
            </tr>
            <tr v-if="customers.length > 1">
              <td>{{ $t("customers") }}:</td>
              <td>{{ customers.length }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import formatMoney from "@/utils/mixins/formatMoney";

export default {
  name: "Overview",
  mixins: [formatMoney],
  data: () => ({
    loading: true,
    customerDetails: {},
    customers: [],
    invoices: [],
  }),
  methods: {
    readCustomerDetails: function () {
      if (this.$store.getters.isReady) {
        var that = this;
        this.loading = true;
        this.$http
          .get("customers/" + this.$store.state.session.id)
          .then((response) => {
            that.customerDetails = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(function () {
            that.loading = false;
          });
      }
    },
    readCustomers: function () {
      var that = this;
      this.$http
        .get("customers")
        .then((response) => {
          that.customers = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          that.loading = false;
        });
    },
    readCustomerInvoices: function () {
      var that = this;
      if (this.$store.state.session.apiRoles.includes("API-INV")) {
        this.$http
          .get("invoices")
          .then((response) => {
            that.invoices = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(function () {
            that.loading = false;
          });
      }
    },
    loadData: function () {
      if (this.$store.getters.isReady) {
        this.readCustomerDetails();
        this.readCustomers();
        this.readCustomerInvoices();
      }
    },
  },
  watch: {
    "$store.state.session": "loadData",
  },
  computed: {
    products: function () {
      return this.customerDetails.number_products || 0;
    },
    activeProducts: function () {
      return this.customerDetails.number_active_products || 0;
    },
    openInvoices: function () {
      return this.invoices.filter(function (invoice) {
        return invoice.paid === false;
      }).length;
    },
    openInvoicesSum: function () {
      var sum = 0;
      for (var i = 0; i < this.invoices.length; i++) {
        if (this.invoices[i].paid === false) {
          sum +=
            this.invoices[i].open_amount != null
              ? this.invoices[i].open_amount
              : this.invoices[i].total_amount;
        }
      }
      return sum;
    },
  },
  mounted: function () {
    this.loadData();
  },
};
</script>

<i18n>
{
  "en": {
    "overview": "Overview",
    "products": "Products",
    "active products": "Active products",
    "open invoices": "Open invoices",
    "open invoices amount": "Open invoices amount"
  },
  "de": {
    "overview": "Übersicht",
    "products": "Produkte",
    "active products": "Aktive Produkte",
    "open invoices": "Offene Rechnungen",
    "open invoices amount": "Offener Rechnungsbetrag"
  }
}
</i18n>
