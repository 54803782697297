<template>
  <v-card :loading="loading" v-show="showComponent">
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        <v-icon class="mr-4">mdi-text-box-multiple</v-icon>
        {{ $t("latest products") }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="d-flex">
      <v-card
        v-for="(product, idx) in products"
        :key="idx"
        class="mx-2 my-2"
        width="160"
        height="auto"
        outlined
      >
        <v-card-title>
          <v-chip
            color="primary"
            dense
            outlined
            link
            :to="{
              name: 'product-details',
              params: {
                productId: product.id,
                openAttr: true,
              },
            }"
          >
            {{ product.product_type_name }}
          </v-chip>
        </v-card-title>
        <v-card-text>
          {{ product.description ? product.description : product.product_name }}
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import formatMoney from "@/utils/mixins/formatMoney";

export default {
  name: "LatestProducts",
  mixins: [formatMoney],
  props: {
    numberOfProducts: {
      type: [String, Number],
      default: 6,
    },
  },
  data: () => ({
    loading: true,
    products: [],
  }),
  computed: {
    showComponent() {
      return this.products.length > 0;
    },
  },
  methods: {
    getProducts() {
      var that = this;
      var latestDate = this.$moment(new Date()).subtract(6, "months");
      this.$http
        .get("products", {
          params: {
            active: true,
            page_size: this.numberOfProducts,
            order_by: 27, // created
          },
        })
        .then((response) => {
          that.products = response.data.results.filter(
            (product) =>
              product.billing_start_date &&
              that.$moment(product.billing_start_date).isAfter(latestDate)
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  watch: {
    "$store.state.session": "getProducts",
  },

  mounted() {
    this.getProducts();
  },
};
</script>

<style scoped>
.scroll {
  overflow-y: scroll;
}
</style>

<i18n>
{
  "en": {
    "latest products": "Latest products"
  },
  "de": {
    "latest products": "Neueste Produkte"
  }
}
</i18n>
