<template>
  <dashboard v-if="$store.getters.isReady" />
</template>

<script>
import Dashboard from "@/components/dashboard/Dashboard";

export default {
  name: "Home",
  components: {
    Dashboard,
  },
};
</script>
