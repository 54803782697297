var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showComponent),expression:"showComponent"}],attrs:{"loading":_vm.loading}},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-text-box-multiple")]),_vm._v(" "+_vm._s(_vm.$t("latest products"))+" ")],1)],1),_c('v-card-text',{staticClass:"d-flex"},_vm._l((_vm.products),function(product,idx){return _c('v-card',{key:idx,staticClass:"mx-2 my-2",attrs:{"width":"160","height":"auto","outlined":""}},[_c('v-card-title',[_c('v-chip',{attrs:{"color":"primary","dense":"","outlined":"","link":"","to":{
            name: 'product-details',
            params: {
              productId: product.id,
              openAttr: true,
            },
          }}},[_vm._v(" "+_vm._s(product.product_type_name)+" ")])],1),_c('v-card-text',[_vm._v(" "+_vm._s(product.description ? product.description : product.product_name)+" ")])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }