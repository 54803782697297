<template>
  <messages
    :title="$t('latest news')"
    title-icon="mdi-email-outline"
    page-size="5"
    :only-scope="['news']"
    days-ago="180"
    ignore-staff
    no-server-pagination
    no-scope
    no-search
    no-filter
    no-reload
    no-dedicated
  />
</template>

<script>
import Messages from "@/components/msg/Messages";

export default {
  name: "NewsList",
  components: {
    Messages,
  },
};
</script>

<i18n>
  {
    "en": {
      "latest news": "News"
    },
    "de": {
      "latest news": "aktuelle Nachrichten"
    }
  }
</i18n>
