<template>
  <v-card :loading="loading" v-show="showComponent">
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        <v-icon class="mr-4">mdi-cash</v-icon>
        {{ $t("latest invoices") }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="d-flex">
      <v-card
        v-for="(invoice, idx) in invoices"
        :key="idx"
        class="mx-2 my-2"
        width="160"
        height="auto"
        outlined
      >
        <v-card-title>
          <v-chip
            :color="invoice.paid ? 'green' : 'orange'"
            dense
            outlined
            link
            :to="{
              name: 'invoice-details',
              params: {
                invoiceId: invoice.id,
              },
            }"
          >
            {{ formatDate(invoice.create_date) }}
          </v-chip>
        </v-card-title>
        <v-card-text>
          {{ invoice.description }}
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import formatDate from "@/utils/mixins/formatDate";

export default {
  name: "LatestInvoices",
  mixins: [formatDate],
  props: {
    numberOfInvoices: {
      type: [String, Number],
      default: 6,
    },
  },
  computed: {
    showComponent() {
      return this.invoices.length > 0;
    },
  },
  data: () => ({
    loading: true,
    invoices: [],
    invoicesNumber: 0,
  }),
  methods: {
    getInvoices() {
      var that = this;
      this.$http
        .get("invoices", {
          params: {
            paid: false,
          },
        })
        .then((response) => {
          that.invoicesNumber = response.data.length;
          that.invoices = response.data
            .sort(function (a, b) {
              if (a.create_date < b.create_date) {
                return 1;
              } else if (a.create_date > b.create_date) {
                return -1;
              } else {
                return 0;
              }
            })
            .slice(0, that.numberOfInvoices);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  watch: {
    "$store.state.session": "getInvoices",
  },

  mounted() {
    this.getInvoices();
  },
};
</script>

<i18n>
{
  "en": {
    "latest invoices": "Latest invoices"
  },
  "de": {
    "latest invoices": "Letzte Rechnungen"
  }
}
</i18n>
