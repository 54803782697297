<template>
  <v-card :loading="loading">
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        <v-icon class="mr-4">mdi-message-outline</v-icon>
        {{ $t("recommendation marketing") }}
      </v-toolbar-title>
    </v-toolbar>

    <v-card-text v-if="hasCode">
      <v-row>
        <v-col cols="12" class="text-center">
          <v-text-field v-model="referralCode" :label="$t('your code')" id="secretTextFieldforCopy"
            append-icon="mdi-content-copy" readonly outlined @click="copy" @click:append="copy" />
        </v-col>
      </v-row>
      <v-data-table :headers="headers" :items="referrals" :items-per-page="5" :no-data-text="$t('code wasnt used')">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              {{ $t("times your code was used") }}
            </v-toolbar-title>
          </v-toolbar>
        </template>

        <template v-slot:item.ordered="{ item }">
          <div v-if="item.ordered !== null" class="monospace">
            {{ formatDate(item.ordered) }}
          </div>
          <div v-else class="monospace">-</div>
        </template>

        <template v-slot:item.credit="{ item }">
          <div class="monospace text-no-wrap">
            {{ formatMoney(item.credit, "CHF") }}
          </div>
        </template>

        <template v-slot:item.status="{ item }">
          <div v-if="item.credit_canceled">{{ $t("cancelled") }}</div>
          <div v-else-if="item.credit_paid_out">
            {{ $t("paid out:") }} {{ formatDate(item.credit_paid_out) }}
          </div>
          <div v-else-if="item.ordered">{{ $t("waiting") }}</div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-text v-else>
      <br />
      <br />
      <br />
      <br />
    </v-card-text>

    <v-card-actions>
      <v-dialog v-model="dialog" scrollable max-width="800px" v-if="!hasCode">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" text>
            {{ $t("generatecode") }}
          </v-btn>
        </template>
        <v-card>
          <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title>
              {{ $t("conditionsTitle") }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="mt-5 text-justify">
            <div v-html="$t('conditionsText')" />
            <div v-html="$t('conditionsLink')" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="dialog = false">{{ $t("close") }}</v-btn>
            <v-btn color="primary" :disabled="!isAllowed" @click="
              dialog = false; generateCode();
            ">
              {{ $t("confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import copyToClipboard from "@/utils/mixins/copyToClipboard";
import formatMoney from "@/utils/mixins/formatMoney";
import formatDate from "@/utils/mixins/formatDate";

export default {
  name: "Referral",
  mixins: [copyToClipboard, formatMoney, formatDate],
  data: () => ({
    dialog: false,
    loading: true,
    hasCode: false,
    referralCode: null,
    referrals: [],
    customerDetails: null,
  }),
  computed: {
    isAllowed() {
      return (
        this.customerDetails != null &&
        this.customerDetails.number_active_products > 0 &&
        this.customerDetails.partner_type == "END"
      );
    },
    headers() {
      return [
        { value: "company_name", text: this.$i18n.t("company_name") },
        { value: "first_name", text: this.$i18n.t("first_name") },
        { value: "last_name", text: this.$i18n.t("last_name") },
        { value: "ordered", text: this.$i18n.t("ordered") },
        ,
        { value: "credit", text: this.$i18n.t("credit") },
        { value: "status", text: this.$i18n.t("status") },
      ];
    },
  },
  methods: {
    copy() {
      this.copyToClipboard(
        "secretTextFieldforCopy",
        this.$t("CopyConfirmation")
      );
    },
    getCode: function () {
      if (this.$store.getters.isReady) {
        var that = this;
        this.loading = true;
        this.$http
          .get("customers/" + this.$store.state.session.id + "/referrer", {
            disableDefaultErrorHandler: true,
          })
          .then((response) => {
            that.hasCode = true;
            that.referralCode = response.data.code;
            that.referrals = response.data.orders;
          })
          .catch((error) => {
            that.hasCode = false;
          })
          .finally(function () {
            that.loading = false;
          });
      }
    },
    generateCode: function () {
      if (this.$store.getters.isReady) {
        var that = this;
        this.loading = true;
        this.$http
          .post(
            "customers/" + this.$store.state.session.id + "/referrer",
            {},
            {
              disableDefaultErrorHandler: true,
            }
          )
          .then((response) => {
            that.hasCode = true;
            that.referralCode = response.data.code;
            that.referrals = response.data.orders;
          })
          .catch((error) => {
            that.hasCode = false;
            that.$snotify.error(error.message, {
              timeout: 0,
              showProgressBar: false,
              closeOnClick: false,
              buttons: [
                {
                  text: "OK",
                  action: (toast) => {
                    that.$snotify.remove(toast.id);
                  },
                  bold: true,
                },
              ],
            });
          })
          .finally(function () {
            that.loading = false;
          });
      }
    },
    getDetails: function () {
      if (this.$store.getters.isReady) {
        var that = this;
        this.$http
          .get("customers/" + this.$store.state.session.id)
          .then((response) => {
            that.customerDetails = response.data;
          })
          .catch((error) => { });
      }
    },
  },
  mounted() {
    // reload if session has been changed
    this.$store.watch(
      (state) => state.session.isReady,
      (value) => {
        if (value) {
          this.getCode();
          this.getDetails();
        }
      }
    );
    this.getCode();
    this.getDetails();
  },
};
</script>

<i18n>
{
  "en": {
    "confirm":"Confirm",
    "close":"Close",
    "company_name":"Company name",
    "first_name":"First name",
    "last_name":"Last name",
    "ordered":"Use date",
    "credit":"Discount",
    "status":"Status",
    "your code":"Recommendation code",
    "generatecode": "Generate recommendation code",
    "times your code was used": "Your code was used as follows:",
    "cancelled":"Cancelled",
    "waiting":"Waiting for billing",
    "paid out:":"Redeemed on:",
    "code wasnt used":"Your code has not been used yet",
    "CopyConfirmation":"Copied to clipboard",
    "recommendation marketing": "Recommend iWay",
    "conditionsTitle": "Recommend iWay",
    "conditionsText": "<p>All customers who purchase at least one product from iWay are eligible to participate. Sales partners, wholesale partners and customers in a terminated relationship are excluded. In principle, all people who are not yet active customers of iWay are allowed to redeem the referral code. The code may not be misused and may not be used commercially by third parties.</p><p>The recommended person can redeem the referral code for the following products: INTERNET, TV, MOBILE, TELEPHONE (Home basic, Home flat, Home flat plus , myKMU Basic, myKMU Flat and myKMU Flat Plus) or a combination of these products. The recommended person must provide the referral code directly when placing the order; we cannot take any subsequent information into account. The order can be made via the website or in writing. You can pass on the recommendation code as often as you like.</p><p>We do not pay out any bonuses, the bonus is given as an invoice credit. The referred person will receive the bonus credited to their first invoice. You will receive the reward credited to your bill after the referred person pays their first bill. We only send invoices with an amount over CHF 10. If the amount is less, we will move it to the following invoice.</p><p>If the recommended person is already a customer or uses the code improperly or commercially, If you cancel the iWay services after the free trial period or are unable to conclude a contract due to a lack of a positive credit check, neither you nor the recommended person are entitled to the bonus.</p><p>We protect the data in accordance with our data protection declaration. We reserve the right to stop the offer or adjust the amount of the reward at any time.</p>",
    "conditionsLink": "Further details can be found at <a href='https://www.iway.ch/ueber-iway/iway-empfehlen/' target='_blank'>'recommend iWay'</a>."
  },
  "de": {
    "confirm":"Bestätigen",
    "close":"Schliessen",
    "company_name":"Firmenname",
    "first_name":"Vorname",
    "last_name":"Nachname",
    "ordered":"Nutzdatum",
    "credit":"Rabatt",
    "status":"Status",
    "your code":"Empfehlungscode",
    "generatecode": "Empfehlungscode generieren",
    "times your code was used": "Ihr Code wurde folgendermassen benutzt:",
    "cancelled":"Abgebrochen",
    "waiting":"Wartet auf Verrechnung",
    "paid out:":"Verrechnet am:",
    "code wasnt used":"Der Code wurde noch nicht verwendet",
    "CopyConfirmation":"In die Zwischenablage kopiert",
    "recommendation marketing": "iWay empfehlen",
    "conditionsTitle": "iWay Empfehlen",
    "conditionsText": "<p>Teilnahmeberechtigt sind alle Kundinnen und Kunden, die mindestens ein Produkt von iWay beziehen. Ausgeschlossen sind Sales Partner, Wholesale Partner sowie Kundinnen und Kunden in einem gekündigten Verhältnis. Grundsätzlich dürfen alle Personen, die noch nicht aktive Kundinnen und Kunden von iWay sind, den Empfehlungscode einlösen. Der Code darf nicht missbräuchlich verwendet werden und nicht kommerziell durch Drittanbieter genutzt werden.</p><p>Die empfohlene Person kann den Empfehlungscode für folgende Produkte einlösen: INTERNET, TV, MOBILE, TELEFONIE (Home basic, Home flat, Home flat plus, myKMU Basic, myKMU Flat und myKMU Flat Plus) oder eine Kombination dieser Produkte. Die empfohlene Person muss den Empfehlungscode direkt bei der Bestellung angeben, nachträgliche Angaben können wir nicht berücksichtigen. Die Bestellung kann via Website oder schriftlich erfolgen. Sie dürfen den Empfehlungsode beliebig oft weitergeben.</p><p>Wir bezahlen keine Prämien aus, die Prämie erfolgt als Rechnungsgutschrift. Die angeworbene Person erhält die Prämie auf ihrer ersten Rechnung gutgeschrieben. Sie erhalten die Prämie auf Ihrer Rechnung gutgeschrieben, nachdem die angeworbene Person ihre erste Rechnung bezahlt hat. Wir verschicken nur Rechnungen mit einem Betrag über CHF 10.– Sollte der Betrag darunter liegen, verschieben wir diesen auf die folgende Rechnung.</p><p>Sollte die empfohlene Person bereits Kundin oder Kunde sein, den Code missbräuchlich oder kommerziell verwenden, nach der kostenlosen Testphase die iWay-Dienstleistungen kündigen oder mangels positiver Bonitätsprüfung keinen Vertrag abschliessen können, haben weder Sie, noch die empfohlene Person einen Anspruch auf die Prämie.</p><p>Wir schützen die Daten gemäss unserer Datenschutzerklärung. Wir behalten uns das Recht vor, das Angebot jederzeit zu stoppen oder die Höhe der Prämie anzupassen.</p>",
    "conditionsLink": "Die weiteren Einzelheiten finden Sie unter <a href='https://www.iway.ch/ueber-iway/iway-empfehlen/' target='_blank'>'iWay empfehlen'</a>."
  }
}
</i18n>
