<template>
  <v-container fluid class="">
    <v-fab-transition>
      <v-btn color="secondary" class="mt-8" @click="dialogTicket = !dialogTicket" absolute top right fab small>
        <v-icon color="secondaryText">mdi-face-agent</v-icon>
      </v-btn>
    </v-fab-transition>

    <div class="d-flex flex-wrap">
      <support-ticket v-if="dialogTicket" class="mr-4 mb-4" style="min-width: 100%;"
        :customer-id="$store.getters.customerId" @canceled="dialogTicket = false" @created="dialogTicket = false" />
      <news-list class="mr-4 mb-4" style="min-width: 100%;" />
      <dedicated-messages v-if="!hideDedicatedMessages" class="mr-4 mb-4 w-100" style="min-width: 100%;"
        @foundDedicatedMessages="
          hideDedicatedMessages = $event && $event > 0 ? false : true
          " />
      <latest-products class="mr-4 mb-4" style="min-width: 100%;" v-if="$store.getters.apiRoles.includes('API-PROD')" />
      <latest-invoices class="mr-4 mb-4" style="min-width: 100%;" v-if="$store.getters.apiRoles.includes('API-INV')" />
      <referral class="mr-4 mb-4" style="min-width: 30%;" v-if="$store.state.session.partnerType === 'END' &&
        $config.mandant == 'iway'
        " />
      <overview class="mr-4 mb-4" style="min-width: 30%;" />
    </div>

  </v-container>
</template>

<script>
import NewsList from "./NewsList";
import DedicatedMessages from "./DedicatedMessages";
import Overview from "./Overview";
import Referral from "./Referral";
import LatestProducts from "./LatestProducts";
import LatestInvoices from "./LatestInvoices";
import SupportTicket from "@/components/products/SupportTicket";

export default {
  name: "Dashboard",
  data: () => ({
    hideDedicatedMessages: false,
    dialogTicket: false,
  }),
  components: {
    NewsList,
    DedicatedMessages,
    Overview,
    Referral,
    LatestProducts,
    LatestInvoices,
    SupportTicket,
  },
};
</script>
